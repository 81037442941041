<template>
	<div class="admin-order-renew ">
		<div class="el-content">
			<a-space>
				<a-select v-model:value="search.pay_method" style="width: 150px">
					<a-select-option value="">请选择支付方式</a-select-option>
					<a-select-option value="1">微信支付</a-select-option>
					<a-select-option value="2">支付宝支付</a-select-option>
				</a-select>
				<com-server-select @success="e=>search.server_id = e"></com-server-select>
				<a-input v-model:value="search.order_number" placeholder="订单编号" style="width: 240px;">
					<template #addonBefore>
						<a-select v-model:value="search.pay_status" style="width: 90px">
							<a-select-option value="">全部</a-select-option>
							<a-select-option value="0">未支付</a-select-option>
							<a-select-option value="1">已支付</a-select-option>
						</a-select>
					</template>
				</a-input>
				<a-input v-model:value="search.program_id" placeholder="应用APPID" style="width: 240px;"></a-input>
				<a-button type="primary" @click="getOrderList(1,info.limit)"><i class="ri-search-2-line"></i>查询</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'id',dataIndex:'id'},
				{title:'订单编号',dataIndex:'order_number'},
				{title:'续费应用',dataIndex:'program',slots:{customRender:'program'}},
				{title:'续费用户',dataIndex:'user',slots:{customRender:'user'}},
				{title:'续费版本',dataIndex:'server_bak.server_name'},
				{title:'版本说明',dataIndex:'server_bak.description'},
				{title:'订单信息',dataIndex:'info',slots:{customRender:'info'}},
				{title:'订单创建时间',dataIndex:'create_time'},
			]" >
				<template #program="{record}">
					<div v-if="record.program" style="display: flex;align-items: center;">
						<img :src="record.program.logo" style="width: 30px;height: 30px;border-radius: 50%;margin-right: 8px;" alt="">
						<div>
							<div>{{record.program.program_name}}</div>
							<div>APPID:{{record.program.program_id}}</div>
						</div>
					</div>
				</template>
				<template #user="{record}">
					<div v-if="record.user" >
						<div>userid：{{record.user.user_id}}</div>
						<div>昵称：{{record.user.name}}</div>
						<div>电话：{{record.user.mobile}}</div>
					</div>
				</template>
				<template #info="{record}">
					<div>订单金额：{{record.total_price}}</div>
					<div>
						订单状态：
						<a-tag :color="getOrderStatus(record,true)">{{getOrderStatus(record,false)}}</a-tag>
					</div>
					<div v-if="record.pay_status == 1">支付时间：{{record.pay_time}}</div>
					<div v-if="record.pay_status == 1">支付方式：{{$util.payMethod(record.pay_method)}}</div>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination 
				show-size-changer 
				:default-current="info.page" 
				:total="info.count" 
				@showSizeChange="(page,e)=>{ getOrderList(info.page, e); }"
				@change="e=>{getOrderList(e, info.limit);}"/>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import appModel from '@/api/saas/app.js'
import comServerSelect from '@/components/admin/com-server-select.vue'
export default{
	components:{
		comServerSelect
	},
	setup(){
		const _d =reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			search:{
				order_number:'',
				program_id:'',
				pay_method:'',
				pay_status:'',
				server_id:''
			},
			serverList:[],
		})
		const getOrderStatus = computed(()=>{
			return function (e,t){
				if( e.is_refund == 2 ) return t ? 'danger':'已退款'
				if( e.pay_status == 1 && e.is_cancel == 0) return t ? '#00CC66':'已支付'
				if( e.pay_status == 0 ) return t ? '#FF3366':'未支付'
				if( e.is_cancel == 1) return t ? '#FF3366':'已取消'
			}
		})	
		getOrderList(1,_d.info.limit)

		function getOrderList(page,limit){
			appModel.getAppOrder(page,limit,_d.search ,res=>_d.info = {limit,...res})
		}
		return{
			...toRefs(_d),
			getOrderList,
			getOrderStatus
		}
	}
}
</script>

<style lang="scss">
</style>
